import { DefaultHomePageProps } from '@/common/props/page.props';
import { IQueryPageProps } from '@/common/types/page.types';
import ContainerStyles from '@/components/container/Container.styles';
import PAGE_CONTENT_CONTAINER from '@/components/container/PageContentContainer.component';
import HERO from '@/components/hero/Hero.component';
import ARTICLE_PREVIEW from '@/components/list/ArticlePreview.component';
import SEO from '@/components/Seo';
import firebase from '@/firebase-config';
import { Divider } from '@chakra-ui/react';
import { graphql, StaticQueryDocument } from 'gatsby';
import { get } from 'lodash';
import React, { useEffect } from 'react';

/**
 * RootIndex
 * @param {IQueryPageProps<Queries.HomeQuery>} {
 *   data,
 * }
 * @return {*}  {React.ReactNode}
 */
function RootIndex({ data, location }: IQueryPageProps<any>): React.ReactNode {
  const posts = get(data, 'allContentfulBlogPost.nodes');
  const [page] = get(data, 'allContentfulPage.nodes');
  const { heroImage, title, sections } = page;
  const { seoTitle, seoDescription, seoKeywords } = DefaultHomePageProps;

  // @todo: Blog Post On This - Dynamic Chakra Props
  // const borderRadiusKey = titlePostition === 'left' ? 'Right' : 'Left';
  // const positionProps = {
  //   [titlePostition]: '12.5rem',
  // };
  // const borderProps = {
  //   [`borderTop${borderRadiusKey}Radius`]: '0.65rem',
  // };

  // useEffect(() => {
  //   firebase
  //     .firestore()
  //     .collection('subscribers')
  //     .add({
  //       firstName: 'Beefy',
  //       lastName: 'Titts',
  //       emailAddress: 'befreestudios@gmail.com',
  //     })
  //     .then((ref: { id: any }) => {
  //       console.log('Added document with ID: ', ref.id);
  //     });
  // });

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        keywords={seoKeywords}
        pathname={location.pathname}
      />
      <HERO showTitle image={heroImage.gatsbyImage} title={title} />
      <ContainerStyles.PageContainer>
        {sections.map((section: Queries.ContentfulContentBlock) => (
          <React.Fragment key={section.id}>
            <PAGE_CONTENT_CONTAINER {...section} key={section.id} />
            <Divider
              margin={{
                base: '32px auto',
                md: '48px auto',
              }}
            />
          </React.Fragment>
        ))}
        <ARTICLE_PREVIEW posts={posts} />
      </ContainerStyles.PageContainer>
    </>
  );
}

export default RootIndex;

export const homePageQuery: StaticQueryDocument = graphql`
  query Home {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        title
        slug
        publishDate(formatString: "MMMM Do, YYYY")
        author {
          name
        }
        tags {
          tagText
        }
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 212
          )
        }
        description {
          raw
        }
      }
    }
    allContentfulPage(
      filter: { contentful_id: { eq: "5e1MxCh5Xv8nhdZ3Gvf6AT" } }
    ) {
      nodes {
        title
        subTitle
        slug
        url {
          id
          url
        }
        heroImage: heroImage {
          gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 1680)
        }
        sections {
          id
          title
          content {
            raw
          }
          image: image {
            gatsbyImage(layout: CONSTRAINED, placeholder: BLURRED, width: 1180)
          }
          imageAlt
          imageLeftAligned
          ctaPath
          ctaLabel
          showCta
        }
      }
    }
  }
`;
